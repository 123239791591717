<div [formGroup]="_form" class="validation-requirement">
  <div class="header">
    <label fxFlex="70">Validation Requirement</label>
    <label
      fxFlex="30"
      [hidden]="itemType == 'no-errors' || itemType == 'project-has-been-run'"
      >Specified File</label
    >
  </div>
  <div class="requirements">
    <div class="requirement" fxFlex="70">
      <ng-container [ngSwitch]="itemType">
        <app-contains-validation-item
          *ngSwitchCase="'contains'"
          [appParentForm]="_form"
          [appChildFormValue]="requirement">
        </app-contains-validation-item>

        <app-perfect-match-validation-item
          *ngSwitchCase="'perfect-match'"
          [appParentForm]="_form"
          [appChildFormValue]="requirement">
        </app-perfect-match-validation-item>

        <app-variable-validation
          *ngSwitchCase="'variable-validation'"
          [appParentForm]="_form"
          [appChildFormValue]="requirement">
        </app-variable-validation>

        <app-no-requirements-validation-item *ngSwitchDefault>
        </app-no-requirements-validation-item>
      </ng-container>
    </div>
    <div
      class="file"
      fxFlex="30"
      [hidden]="itemType == 'no-errors' || itemType == 'project-has-been-run'">
      <mat-form-field>
        <mat-select formControlName="file">
          <mat-option value="main.ts">main.ts</mat-option>
          <mat-option value="main.blocks">main.blocks</mat-option>
          <mat-option value="images.g.ts">images.g.ts</mat-option>
          <mat-option value="images.g.jres">images.g.jres</mat-option>
          <mat-option value="tilemap.g.ts">tilemap.g.ts</mat-option>
          <mat-option value="tilemap.g.jres">tilemap.g.jres</mat-option>
          <mat-option value="assets.json">assets.json</mat-option>
          <mat-option value="pxt.json">pxt.json</mat-option>
          <mat-option value="README.md">README.md</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="validateInsideFunctionHeader">
        <label>Validate Inside</label>
      </div>
      <div class="validateInsideBlockType">
        <mat-form-field>
          <mat-select formControlName="validateInsideBlockType">
            <mat-option value=" ">Complete Code</mat-option>
            <mat-option value="if-condition">If Condition</mat-option>
            <mat-option value="if-block">If Block</mat-option>
            <mat-option value="else-if-condition">Else If Condition</mat-option>
            <mat-option value="else-if-block">Else If Block</mat-option>
            <mat-option value="else-block">Else Block</mat-option>
            <mat-option value="for-block">For Block</mat-option>
            <mat-option value="while-condition">While Condition</mat-option>
            <mat-option value="while-block">While Block</mat-option>
            <mat-option value="forever-block">Forever Block</mat-option>

            <mat-option value="sprites-onOverlap-block"
              >Sprites onOverlap</mat-option
            >
            <mat-option value="sprites-onCreated-block"
              >Sprites onCreated</mat-option
            >
            <mat-option value="sprites-onDestroyed-block"
              >Sprites onDestroyed</mat-option
            >
            <mat-option value="controller-onEvent-block"
              >Controller onEvent</mat-option
            >
            <mat-option value="controller-onButtonEvent-block"
              >Controller onButtonEvent</mat-option
            >
            <mat-option value="game-onUpdate-block">Game onUpdate</mat-option>
            <mat-option value="game-onUpdateInterval-block"
              >Game onUpdateInterval</mat-option
            >
            <mat-option value="scene-onOverlapTile-block"
              >Scene onOverlapTile</mat-option
            >
            <mat-option value="scene-onHitWall-block"
              >Scene onHitWall</mat-option
            >
            <mat-option value="info-onScore-block">Info onScore</mat-option>
            <mat-option value="info-onCountdownEnd-block"
              >Info onCountdownEnd</mat-option
            >
            <mat-option value="info-onLifeZero-block"
              >Info onLifeZero</mat-option
            >
            <mat-option value="function-doSomething-block"
              >Functions doSomething</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
