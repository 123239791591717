export interface ValidationItem {
  text: string;
  type: ValidationItemType;
  points: number;
  failureText: string;
  mustCompleteAll: boolean;
  requirements: ValidationRequirements;
  wildCardVariableName?: string;
  wildCardParam1?: string;
  wildCardParam2?: string;
}

export type ValidationItemFile =
  | "main.ts"
  | "main.blocks"
  | "images.g.ts"
  | "images.g.jres"
  | "tilemap.g.jres"
  | "tilemap.g.ts"
  | "assets.json"
  | "pxt.json"
  | "README.md";

export const defaultValidationRequirement: ValidationRequirement = {
  file: "main.ts",
};

export const defaultValidationItems: ValidationItem[] = [
  {
    text: "No project errors exist",
    type: "no-errors",
    failureText: "",
    points: 0,
    mustCompleteAll: false,
    requirements: [defaultValidationRequirement],
  },
  {
    text: "Project has been run",
    type: "project-has-been-run",
    failureText: "",
    points: 0,
    mustCompleteAll: false,
    requirements: [defaultValidationRequirement],
  },
];

export type ValidationItems = ValidationItem[];

export type ValidationItemType =
  | "contains"
  | "perfect-match"
  | "anything-has-changed"
  | "project-has-been-run"
  | "no-errors"
  | "variable-validation";

export interface ValidationRequirement {
  file: ValidationItemFile;
}

export type ValidationRequirements = ValidationRequirement[];

export interface ContainsValidationItem extends ValidationRequirement {
  instanceCount: number;
  functionName: string;
  parameters: ValidationItemParameter[];
}

export interface VariableValidationItem extends ValidationRequirement {
  variableName: string;
  parameters: ValidationItemParameter[];
}

export interface ContainsMusicValidationItem extends ValidationRequirement {
  timing: string;
  music: string;
}

export interface ContainsEffectValidationItem extends ValidationRequirement {
  eventType: string;
  effect: string;
}

export interface ValidationItemParameter {
  operator: ParameterOperator;
  value?: string;
}

export interface InclusiveRangedParameter {
  operator: ParameterOperator;
  minValue?: string;
  maxValue?: string;
}

export interface OrRangedParameter {
  operator: ParameterOperator;
  minValue?: string;
  maxValue?: string;
}

export type ParameterOperator =
  | "gte"
  | "lte"
  | "eq"
  | "gt"
  | "lt"
  | "contains"
  | "neq"
  | "ignore"
  | "inclusive"
  | "or";

export interface PerfectMatchValidationItem extends ValidationRequirement {
  match: string;
}
