import { KeyValue } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
  ParameterOperator,
  ValidationItemParameter,
  InclusiveRangedParameter,
  OrRangedParameter,
} from "src/app/models";

@Component({
  selector: "app-parameter",
  templateUrl: "./parameter.component.html",
  styleUrls: ["./parameter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ParameterComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ParameterComponent,
    },
  ],
})
export class ParameterComponent
  implements ControlValueAccessor, OnDestroy, Validator
{
  constructor(private cdr: ChangeDetectorRef) {
    this.onChangeSubs.push(
      this.operator.valueChanges.subscribe((value: ParameterOperator) => {
        if (value === "inclusive") {
          this._form.addControl(
            "minValue",
            new FormControl(
              this._form.contains("minValue") ? this.minValueControl.value : "",
              [Validators.required, Validators.pattern("-?(\\d+)(\\.)?(\\d+)?")]
            )
          );
          this._form.addControl(
            "maxValue",
            new FormControl(
              this._form.contains("maxValue") ? this.maxValueControl.value : "",
              [Validators.required, Validators.pattern("-?(\\d+)(\\.)?(\\d+)?")]
            )
          );
          this.minValueControl.setValidators(this.checkMinMaxValues());
          this.maxValueControl.setValidators(this.checkMinMaxValues());
          this.removeFormGroupControl(["value"]);
        } else if (value === "or") {
          this._form.addControl(
            "minValue",
            new FormControl(
              this._form.contains("minValue") ? this.paramOneControl.value : "",
              [Validators.required]
            )
          );
          this._form.addControl(
            "maxValue",
            new FormControl(
              this._form.contains("maxValue") ? this.paramTwoControl.value : "",
              [Validators.required]
            )
          );
          this.paramOneControl.setValidators(this.checkParamValues());
          this.paramTwoControl.setValidators(this.checkParamValues());
          this.removeFormGroupControl(["value"]);
        } else if (value !== "ignore") {
          this._form.addControl(
            "value",
            new FormControl(
              this._form.contains("value") ? this.valueControl.value : "",
              Validators.required
            )
          );
          this.removeFormGroupControl(["minValue", "maxValue"]);
        } else {
          this.removeFormGroupControl(["minValue", "maxValue", "value"]);
        }
      })
    );
  }

  @HostBinding("class") class = "app-parameter";

  operations: { [operation in ParameterOperator]: string } = {
    gte: ">",
    lte: "<",
    eq: "=",
    neq: "!=",
    gt: ">",
    lt: "<",
    contains: "Contains",
    ignore: "Ignore",
    inclusive: "Inclusive",
    or: "Or",
  };

  _form = new FormGroup({
    operator: new FormControl("eq", Validators.required),
    value: new FormControl("", Validators.required),
    minValue: new FormControl(""),
    maxValue: new FormControl(""),
  });

  get operatorText(): string {
    return this.operations[this.selectedOperator];
  }

  get selectedOperator(): ParameterOperator {
    return this._form.get("operator").value;
  }

  get valid(): boolean {
    return this._form.valid;
  }

  private onTouched: () => void;

  private onChangeSubs: Subscription[] = [];

  private get operator(): FormControl {
    return this._form.get("operator") as FormControl;
  }

  private get valueControl(): FormControl {
    return this._form.get("value") as FormControl;
  }

  private get minValueControl(): FormControl {
    return this._form.get("minValue") as FormControl;
  }

  private get maxValueControl(): FormControl {
    return this._form.get("maxValue") as FormControl;
  }

  private get paramOneControl(): FormControl {
    return this._form.get("minValue") as FormControl;
  }

  private get paramTwoControl(): FormControl {
    return this._form.get("maxValue") as FormControl;
  }

  private removeFormGroupControl(formControlNames: string[]): void {
    formControlNames.forEach((controlName: string) => {
      if (this._form.contains(controlName)) {
        this._form.removeControl(controlName);
      }
    });
  }

  ngOnDestroy(): void {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  writeValue(
    parameter:
      | ValidationItemParameter
      | InclusiveRangedParameter
      | OrRangedParameter
  ): void {
    this._form.patchValue(parameter, { emitEvent: true });
    this.cdr.markForCheck();
  }

  shouldUnderline(operator: ParameterOperator): boolean {
    return operator === "gte" || operator === "lte";
  }

  registerOnChange(onChange: (item: ValidationItemParameter) => void): void {
    const sub = this._form.valueChanges.subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._form[["enable", "disable"][+isDisabled]]();
    this.cdr.markForCheck();
  }

  validate(): ValidationErrors {
    if (this._form.valid) {
      return null;
    }

    let errors: any = {};

    Object.keys(this._form.controls).forEach((controlName) => {
      errors = {
        ...errors,
        ...{ [controlName]: this.addControlErrors(errors, controlName) },
      };
    });

    return errors;
  }

  addControlErrors(
    allErrors: any,
    controlName: string
  ): { [controlName: string]: ValidationErrors } {
    const errors = { ...allErrors };

    const controlErrors = this._form.get(controlName).errors;

    if (controlErrors) {
      errors[controlName] = controlErrors;
    }

    return errors;
  }

  _originalOrder(
    _: KeyValue<ParameterOperator, string>,
    __: KeyValue<ParameterOperator, string>
  ): number {
    return 0;
  }

  private checkMinMaxValues(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (this.minValueControl && this.maxValueControl) {
        const isValid =
          Number(this.minValueControl.value) <
          Number(this.maxValueControl.value);
        if (!isValid) {
          return { errorValue: { value: control.value } };
        } else {
          this.maxValueControl.setErrors(null);
          this.minValueControl.setErrors(null);
          return null;
        }
      }
    };
  }
  private checkParamValues(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.paramOneControl && this.paramTwoControl) {
        const paramOneValue = this.paramOneControl.value;
        const paramTwoValue = this.paramTwoControl.value;
        const isValid =
          paramOneValue.trim() !== "" && paramTwoValue.trim() !== "";
        if (!isValid) {
          return { errorValue: { value: control.value } };
        } else {
          this.paramOneControl.setErrors(null);
          this.paramTwoControl.setErrors(null);
          return null;
        }
      }
      return null;
    };
  }
}
